<template>
  <base-page>
    <Filters model="users" color="users" slot="left-column" />
    <router-view />
  </base-page>
</template>

<script>
import { Filters } from '../../components/filters'

export default {
  name: 'UsersPage',
  components: { Filters },

  meta () {
    return {
      titleTemplate: title => `${title} - Utilisateurs | AGORA`
    }
  },

  preFetch ({ store, currentRoute, previousRoute, redirect, ssrContext }) {
    if (!store.getters['users/getFilters']) {
      store.dispatch('users/fetchFilters')
    }
  },

  mounted () {
    this.$store.commit('pages/setBreadcrumbs', [
      { label: this.$t('global.home'), to: { name: 'home' }, icon: 'home' },
      {}
    ])
    this.$store.commit('pages/setPageTitle', this.$t('users.users_list'))
  }
}
</script>
